<template>
  <div
    class="md:pointer-events-none md:h-[88px] w-full md:pt-2 md:px-12 flex justify-center"
  >
    <nav
      class="transition-[width,_min-width,_background-color] ease-in-out duration-250 pointer-events-auto md:rounded-full md:backdrop-blur-[10px] md:bg-gray-50/80 md:flex md:justify-between md:h-16 md:px-12 md:w-auto w-auto min-w-full opacity-100 overflow-hidden"
      :class="{
        'md:!w-auto md:!min-w-[75%] md:!max-w-auto': isHeaderShiftedUp,
        'bg-white': isMenuOpen,
        'md:hidden': !linksMapped.length,
      }"
    >
      <div
        class="transition-all ease-in-out duration-250 md:text-lg px-4 md:px-0 flex items-center justify-between"
        :class="{
          'mb-10 md:mb-0 mt-5 md:mt-0': isMenuOpen,
          'rounded-full backdrop-blur-[10px] md:backdrop-filter-none bg-gray-50/80 md:bg-transparent px-5 md:px-0 py-3 md:py-0 mt-3 md:mt-0 mx-4':
            !isMenuOpen,
        }"
        @click.prevent="openLocalMenu"
      >
        <h2 class="font-bold whitespace-nowrap text-primary-500">
          {{ label }}
        </h2>

        <button class="md:hidden flex items-center gap-3 leading-none">
          <span
            class="opacity-100 leading-none h-auto font-bold"
            :class="{
              'opacity-0 transition-all ease-in-out duration-250': isMenuOpen,
            }"
          >
            {{ $texts('header.menuLabel', 'Menü') }}
          </span>
          <PageHeaderMenuIcon :is-in-closable-state="isMenuOpen" />
        </button>
      </div>

      <ul
        v-if="linksMapped.length"
        class="flex flex-col md:flex-row gap-8 md:gap-10 opacity-0 md:opacity-100 transition-all ease-in-out duration-250"
        :class="{
          'opacity-100': isMenuOpen,
        }"
      >
        <li v-for="(link, i) in linksMapped" :key="`local_menu_${i}`">
          <VuepalLink
            :to="link.to"
            class="h-16 flex items-center hover:text-primary-500 transition-all ease-in-out duration-250 text-2xl md:text-lg md:font-medium px-3 md:px-0 border-l-4 border-l-transparent md:border-l-0 whitespace-nowrap md:border-b-4 md:border-b-transparent md:border-t-4"
            :class="
              link.isActive
                ? 'font-bold !border-l-primary-500 md:border-t-primary-500'
                : 'md:border-t-transparent'
            "
          >
            {{ link.label }}
          </VuepalLink>
        </li>
      </ul>

      <div class="flex w-16" />
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { MenuLinkTreeElementFirstFragment } from '#build/graphql-operations'

const props = withDefaults(
  defineProps<{
    isHeaderShiftedUp: boolean
    isMenuOpen: boolean
    label?: string
    links?: MenuLinkTreeElementFirstFragment['subtree']
  }>(),
  {
    links: () => [],
    label: undefined,
  },
)

const emit = defineEmits(['menu:open:from-local', 'menu:close:from-local'])

const viewport = useViewport()
const { activeLocalLink } = await useMainMenuLinks()

const linksMapped = computed(() =>
  props.links
    .map((v) => {
      if (v.link.url?.path) {
        const to = v.link.url.path
        return {
          to,
          label: v.link.label,
          isActive: to === activeLocalLink.value?.link.url?.path,
        }
      }
      return null
    })
    .filter(notNullish),
)

function openLocalMenu() {
  if (viewport.isLessThan('lg')) {
    emit('menu:open:from-local')
  }
}
</script>
