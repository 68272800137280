import { default as indexm7A2sbkHbNMeta } from "/builds/bazinga/sv-group/frontend/pages/[...slug]/index.vue?macro=true";
import { default as _91id_937rFlda62EiMeta } from "/builds/bazinga/sv-group/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93p3hejvZIX7Meta } from "/builds/bazinga/sv-group/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexdVG76RGAx9Meta } from "/builds/bazinga/sv-group/frontend/pages/index.vue?macro=true";
import { default as at5V3CHQSEMzMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/at.vue?macro=true";
import { default as chSFRw0UMUiPMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/ch.vue?macro=true";
import { default as decIjDfBbOTkMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/de.vue?macro=true";
import { default as groupDCnjjePtFbMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/group.vue?macro=true";
import { default as hotelvOvgVYep8EMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/hotel.vue?macro=true";
import { default as indexwfUu3or7s8Meta } from "/builds/bazinga/sv-group/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexOYDGwgE0ylMeta } from "/builds/bazinga/sv-group/frontend/pages/press-release/[...slug]/index.vue?macro=true";
import { default as exampleLTHQbfm37jMeta } from "/builds/bazinga/sv-group/frontend/pages/static-page/example.vue?macro=true";
export default [
  {
    name: indexm7A2sbkHbNMeta?.name ?? "slug",
    path: indexm7A2sbkHbNMeta?.path ?? "/:slug(.*)*",
    meta: indexm7A2sbkHbNMeta || {},
    alias: indexm7A2sbkHbNMeta?.alias || [],
    redirect: indexm7A2sbkHbNMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937rFlda62EiMeta?.name ?? "form-id",
    path: _91id_937rFlda62EiMeta?.path ?? "/form/:id()",
    meta: _91id_937rFlda62EiMeta || {},
    alias: _91id_937rFlda62EiMeta?.alias || [],
    redirect: _91id_937rFlda62EiMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/form/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93p3hejvZIX7Meta?.name ?? "form-confirmation-id",
    path: _91id_93p3hejvZIX7Meta?.path ?? "/form/confirmation/:id()",
    meta: _91id_93p3hejvZIX7Meta || {},
    alias: _91id_93p3hejvZIX7Meta?.alias || [],
    redirect: _91id_93p3hejvZIX7Meta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/form/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdVG76RGAx9Meta?.name ?? "index",
    path: indexdVG76RGAx9Meta?.path ?? "/",
    meta: indexdVG76RGAx9Meta || {},
    alias: indexdVG76RGAx9Meta?.alias || [],
    redirect: indexdVG76RGAx9Meta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: at5V3CHQSEMzMeta?.name ?? "landing-page-at",
    path: at5V3CHQSEMzMeta?.path ?? "/landing-page/at",
    meta: at5V3CHQSEMzMeta || {},
    alias: at5V3CHQSEMzMeta?.alias || [],
    redirect: at5V3CHQSEMzMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/at.vue").then(m => m.default || m)
  },
  {
    name: chSFRw0UMUiPMeta?.name ?? "landing-page-ch",
    path: chSFRw0UMUiPMeta?.path ?? "/landing-page/ch",
    meta: chSFRw0UMUiPMeta || {},
    alias: chSFRw0UMUiPMeta?.alias || [],
    redirect: chSFRw0UMUiPMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/ch.vue").then(m => m.default || m)
  },
  {
    name: decIjDfBbOTkMeta?.name ?? "landing-page-de",
    path: decIjDfBbOTkMeta?.path ?? "/landing-page/de",
    meta: decIjDfBbOTkMeta || {},
    alias: decIjDfBbOTkMeta?.alias || [],
    redirect: decIjDfBbOTkMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/de.vue").then(m => m.default || m)
  },
  {
    name: groupDCnjjePtFbMeta?.name ?? "landing-page-group",
    path: groupDCnjjePtFbMeta?.path ?? "/landing-page/group",
    meta: groupDCnjjePtFbMeta || {},
    alias: groupDCnjjePtFbMeta?.alias || [],
    redirect: groupDCnjjePtFbMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/group.vue").then(m => m.default || m)
  },
  {
    name: hotelvOvgVYep8EMeta?.name ?? "landing-page-hotel",
    path: hotelvOvgVYep8EMeta?.path ?? "/landing-page/hotel",
    meta: hotelvOvgVYep8EMeta || {},
    alias: hotelvOvgVYep8EMeta?.alias || [],
    redirect: hotelvOvgVYep8EMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/hotel.vue").then(m => m.default || m)
  },
  {
    name: indexwfUu3or7s8Meta?.name ?? "node-nid",
    path: indexwfUu3or7s8Meta?.path ?? "/node/:nid()",
    meta: indexwfUu3or7s8Meta || {},
    alias: indexwfUu3or7s8Meta?.alias || [],
    redirect: indexwfUu3or7s8Meta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/node/[nid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOYDGwgE0ylMeta?.name ?? "press-release-slug",
    path: indexOYDGwgE0ylMeta?.path ?? "/press-release/:slug(.*)*",
    meta: indexOYDGwgE0ylMeta || {},
    alias: indexOYDGwgE0ylMeta?.alias || [],
    redirect: indexOYDGwgE0ylMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/press-release/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: exampleLTHQbfm37jMeta?.name ?? "static-page-example",
    path: exampleLTHQbfm37jMeta?.path ?? "/static-page/example",
    meta: exampleLTHQbfm37jMeta || {},
    alias: exampleLTHQbfm37jMeta?.alias || [],
    redirect: exampleLTHQbfm37jMeta?.redirect,
    component: () => import("/builds/bazinga/sv-group/frontend/pages/static-page/example.vue").then(m => m.default || m)
  }
]