<template>
  <nav
    class="bg-primary-500 text-white md:px-2 pb-8 md:pb-0 transition-all ease-in-out duration-250"
  >
    <h2 class="md:hidden font-bold px-4 pt-8 pb-10">
      {{ $texts('header.moreAboutSV', 'Mehr über SV') }}
    </h2>

    <ul
      class="flex flex-col md:flex-row gap-8 md:gap-2 h-full text-2xl md:text-base md:font-medium"
    >
      <li
        v-for="(link, i) in nonGastronomyLinks"
        :key="`global_${i}`"
        class="flex items-stretch"
      >
        <VuepalLink
          :to="link.link?.url?.path"
          class="flex items-center hover:bg-primary-600 transition-all ease-in-out duration-250 w-full md:w-auto h-[50px] md:h-auto px-4 md:px-3 border-x-4 md:border-x-0 md:border-y-4 border-r-primary-500 hover:border-r-primary-600 md:border-b-primary-500 hover:border-b-primary-600 pl-2"
          :class="
            link.link?.url?.path === currentActiveMainLink?.link.url?.path
              ? 'font-bold md:font-medium border-l-white md:border-t-white hover:border-b-primary-600'
              : 'border-l-primary-500 md:border-t-primary-500 hover:border-t-primary-600 hover:border-l-primary-600'
          "
        >
          {{ link.link.label }}
        </VuepalLink>
      </li>
      <li
        v-click-away="closeGastronomyDropdown"
        class="flex flex-col overflow-y-hidden transition-all ease-in-out duration-250"
        :style="`--target-height: ${(gastronomyLinks.length + 1) * 50}px`"
        :class="
          gastronomyDropdownOpen ? 'h-[var(--target-height)]' : 'h-[50px]'
        "
      >
        <button
          class="h-[50px] shrink-0 flex gap-0.5 items-center px-3 hover:bg-primary-600 transition-all ease-in-out duration-250 border-x-4 md:border-x-0 md:border-y-4 border-r-primary-500 hover:border-r-primary-600 md:border-b-primary-500 hover:border-b-primary-600 pl-2"
          :class="
            !!activeGastronomyLink
              ? 'font-bold md:font-medium border-l-white md:border-t-white hover:border-b-primary-600'
              : 'border-l-primary-500 md:border-t-primary-500 hover:border-t-primary-600 hover:border-l-primary-600'
          "
          @click="toggleGastronomyDropdown"
        >
          <span class="flex-grow text-left">
            {{
              !!activeGastronomyLink
                ? activeGastronomyLink.link.label
                : $texts(
                    'header.gastronomyChooseLabel',
                    'Gastronomie: Land wählen',
                  )
            }}
          </span>
          <SpriteSymbol
            name="chevron-down"
            class="w-5 h-5 transition-all ease-in-out duration-250"
            :class="gastronomyDropdownOpen ? 'rotate-180' : ''"
          />
        </button>

        <ul class="bg-primary-500 z-50">
          <li
            v-for="(link, i) in gastronomyLinks.filter(
              (l) =>
                !activeGastronomyLink ||
                l.link?.url?.path !== activeGastronomyLink.link.url?.path,
            )"
            :key="`global_gastronomy_${i}`"
          >
            <VuepalLink
              :to="link.link.url?.path"
              class="h-[50px] flex items-center p-3 hover:bg-primary-600 transition-all ease-in-out duration-250"
              @click="closeGastronomyDropdown"
            >
              {{ link.link.label }}
            </VuepalLink>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
defineExpose({
  closeGastronomyDropdown,
})

const {
  gastronomyLinks,
  activeGastronomyLink,
  nonGastronomyLinks,
  currentActiveMainLink,
} = await useMainMenuLinks()

const gastronomyDropdownOpen = ref(false)

function openGastronomyDropdown() {
  gastronomyDropdownOpen.value = true
}

function closeGastronomyDropdown() {
  gastronomyDropdownOpen.value = false
}

function toggleGastronomyDropdown() {
  if (gastronomyDropdownOpen.value) {
    closeGastronomyDropdown()
  } else {
    openGastronomyDropdown()
  }
}
</script>
