<template>
  <div
    class="p-0 py-0 bg-primary-500 text-white md:pr-4 z-10"
    :class="{
      'sticky top-0': isSticky,
    }"
  >
    <ClientOnly>
      <div
        v-click-away="clickAway"
        class="transition-all ease-in-out duration-250 overflow-hidden min-w-[60px]"
        :style="[
          `--target-height-mobile: ${languages.length * 40}px`,
          `--target-height-desktop: ${languages.length * 50}px`,
        ]"
        :class="{
          'h-[40px] md:h-[50px]': !langSwitchDropdownOpen,
          'h-[var(--target-height-mobile)] md:h-[var(--target-height-desktop)] bg-primary-500 rounded-b-2xl text-white z-[100]':
            langSwitchDropdownOpen,
        }"
      >
        <button
          class="p-2 h-[40px] md:h-[50px] flex items-center lg:justify-between w-full transition-all ease-in-out duration-250 hover:text-white hover:bg-primary-600 text-2xl text-base md:font-medium text-white bg-primary-500"
          :disabled="languageLinks.length === 0"
          @click="langSwitchDropdownOpen = !langSwitchDropdownOpen"
        >
          <span class="pr-2">
            {{
              currentLanguage[0].toUpperCase() + currentLanguage.slice(1)
            }}</span
          >
          <SpriteSymbol
            name="chevron-down"
            class="w-6 h-6 transition-all ease-in-out duration-250 text-body text-white"
            :class="{
              'rotate-180': langSwitchDropdownOpen,
            }"
          />
        </button>
        <ul>
          <li v-for="(lang, i) in languageLinks" :key="`lang_${i}`">
            <a
              :href="lang.to"
              class="flex items-center py-[8px] md:py-[12px] px-4 transition-all ease-in-out duration-250 hover:text-white hover:bg-primary-600 cursor-pointer text-2xl text-base md:font-medium text-white"
            >
              {{ lang.code[0].toUpperCase() + lang.code.slice(1) }}
            </a>
          </li>
        </ul>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'

defineProps<{
  isHeaderShiftedUp: boolean
  isSticky: boolean
}>()

type LanguageLink = {
  code: string
  active: boolean
  to: string
}

const languages: ComputedRef<LanguageLink[]> = useLanguageLinks()
const currentLanguage = useCurrentLanguage()
const languageLinks = computed(() => {
  return languages.value.filter((l) => l.code !== currentLanguage.value)
})

const langSwitchDropdownOpen = ref(false)
const clickAway = () => {
  if (langSwitchDropdownOpen.value) {
    langSwitchDropdownOpen.value = false
  }
}
</script>
