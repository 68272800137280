<template>
  <div class="bg-gray-100">
    <div class="container text-gray-900 py-12">
      <div class="grid md:grid-cols-3 gap-12 md:gap-10">
        <div>
          <h3 class="text-base font-bold mb-5">
            {{ $texts('footer.directLinks', 'Direktlinks') }}
          </h3>
          <ul
            v-if="currentActiveMainLink?.subtree?.length"
            class="flex flex-col gap-3 text-base"
          >
            <li
              v-for="(link, i) in currentActiveMainLink?.subtree"
              :key="`footer_link_${i}`"
            >
              <nuxt-link
                :to="link?.link?.url?.path"
                class="relative hover:text-primary-500"
                :class="{
                  'font-bold border-l-4 border-l-primary-500 pl-4 md:pl-4 -ml-4 md:ml-0':
                    link?.link?.url?.path === activeLocalLink?.link.url?.path,
                }"
              >
                {{ link.link.label }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div>
          <h3 class="text-base font-bold mb-5">
            {{
              $texts(
                'footer.contactSwissGastronomy',
                'Kontakt Gastronomie Schweiz',
              )
            }}
          </h3>
          <div class="text-base">
            <address class="not-italic mb-2">
              {{ businessAreaData?.address?.organization }}
              <br />
              {{ businessAreaData?.address?.addressLine1 }}
              <br />
              {{ businessAreaData?.address?.addressLine2 }}
              <br />
              {{ businessAreaData?.address?.postalCode }}
              {{ businessAreaData?.address?.locality }}
            </address>
            <p>
              <a :href="`tel:` + businessAreaData?.telephone">
                {{ businessAreaData?.telephone }}
              </a>
            </p>
            <p>
              <a
                :href="`mailto:` + businessAreaData?.email"
                class="hover:text-primary-500"
                >{{ businessAreaData?.email }}</a
              >
            </p>
          </div>
          <ul
            v-if="businessAreaData?.socialMediaLinks?.length > 0"
            class="flex flex-wrap gap-8 mt-10 md:mt-12"
          >
            <li
              v-for="(link, i) in businessAreaData?.socialMediaLinks"
              :key="`social_link_${i}`"
              class="flex items-center justify-center bg-white rounded-full text-primary-500 w-10 h-10 hover:bg-primary-500 hover:text-white transition-all ease-in-out duration-250"
            >
              <nuxt-link
                :to="link?.uri?.path"
                :aria-label="getIconName(link?.uri?.path)"
              >
                <SpriteSymbol
                  :alt="getIconName(link?.uri?.path)"
                  :name="getIconName(link?.uri?.path)"
                  class="w-6 h-6 flex scale-75"
                />
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div>
          <h3 class="text-base font-bold mb-5">
            {{ $texts('footer.aboutUs', 'Wer wir sind') }}
          </h3>
          <div
            class="text-base font-regular"
            v-html="businessAreaData?.description"
          />
        </div>
      </div>
      <div>
        <ul
          v-if="businessAreaData?.footerLinks?.length > 0"
          class="flex flex-wrap gap-2 text-sm mt-12 md:mt-24"
        >
          <li
            v-for="(item, i) in businessAreaData?.footerLinks"
            :key="`link_${i}`"
            class="flex flex-wrap after:hidden after:[&:not(:last-child)]:block after:content-['|'] after:pl-2"
          >
            <nuxt-link :to="item?.uri?.path" class="hover:text-primary-500">
              {{ item?.title }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { currentActiveMainLink, activeLocalLink } = await useMainMenuLinks()

const props = defineProps<{
  businessAreaKey: String
}>()

function getIconName(url = '') {
  if (url.includes('twitter.com') || url.includes('x.com')) {
    return 'twitter'
  } else if (url.includes('facebook.com')) {
    return 'facebook'
  } else if (url.includes('linkedin.com')) {
    return 'linkedin'
  } else if (url.includes('instagram.com')) {
    return 'instagram'
  } else if (url.includes('youtube.com')) {
    return 'youtube'
  }
}

const businessAreaData = ref({} || undefined)

watchEffect(async () => {
  businessAreaData.value = await fetchBusinessAreaData(
    props.businessAreaKey.toString(),
  )
})

async function fetchBusinessAreaData(key: string) {
  const v = await useGraphqlQuery('businessAreas', {
    key,
  })
  return v?.data.list?.items?.[0]
}
</script>
